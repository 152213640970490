/*
 * Description:
 *  component is created to sepate the query form and reuse in Result page to show query param, 
 *  Form diabled in Result page and only display the field with value
 */
import React from 'react';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import config from "../config";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const CustomInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => (
    <div>
      <input type="text" {...field} {...props}/>
      {touched[field.name] &&
        errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );

const getOption = (value, name) => {
    return (
        <option value={value} key={name}>{name}</option>
    )
  }


const getobstype=(instrument)=>{
    if(instrument==='2dF+AAOMEGA') return config.obs_type_df
    if(instrument==='2dF+HERMES') return config.obs_type_herme
    return config.obs_type
}

export default function QueryTable(props){
    const{is_open,handleTabHide,...others}=props 
    return is_open===undefined? // set is_open undefined for query page
    (<div className="box has-bg-light">
        <QueryForm
            {...others}
        />
    </div>)
    :(  // result page can hide the query
        <div className="Query box">
            {is_open!==undefined&&
            <div style={{width:'100%'}}>
            <h4 className="title " onClick={() => {
                handleTabHide()
            }}>Read Query Details {'   '}
            {!is_open? <KeyboardArrowDownIcon/>: <KeyboardArrowUpIcon />}</h4>
            </div>}
            {is_open!==false&&
            <div className="box has-bg-light">
            
            <QueryForm
                {...others}
            />
            </div>}
        </div>
    )
}

function QueryForm(props) {
    
	const {is_query,showResetQuestion}=props
    const is_disabled=false
    if(is_query===false&&props.initialValues===null) return (<></>)
    const initialValues=props.initialValues??{}
    const obs_type=props.obs_type??getobstype(initialValues.instrument)

    const validateschema = Yup.object({
        radius: Yup.number().max(3600)
            .required('Required'),
        ra: Yup.number().max(360),
        dec: Yup.number().max(180).min(-180),
    })

    const validateFunction = (values) => {
        // validate should be set in query page,in result page, no need to validate
        return {}
    }

    const handleSubmit = (values,setSubmitting)=>{
        // for query page ONLY 
        props.handleSubmit(values,setSubmitting)
    }

    const handleToggleResetQuestion = (value) =>{
        // for query page ONLY 
        props.handleToggleResetQuestion(value)
    }

    const handleInstrumnetChange = (event) =>{
        // for query page and result page 
        props.handleInstrumnetChange(event,getobstype(event.target.value))
    }
  return (
    <Formik
        initialValues={initialValues}
        validationSchema={validateschema}
        validate={is_query?props.validateFunction:validateFunction}
                  // enableReinitialize
        onSubmit={(values,{setSubmitting}) => handleSubmit(values,setSubmitting)}
    >
    {formProps => {
        return (
            <Form>
                {formProps.errors.formLevel && is_query &&
                <div className="notification is-danger is-light">{formProps.errors.formLevel}</div>}
                {!(is_query===false&&initialValues['proID']===undefined)&&
                <div className="field">
                        <label htmlFor="proID" className="label">Program ID</label>
                        <Field
                              name="proID"
                              component={CustomInputComponent}
                              className={clsx('input ', {
                                'is-danger': formProps.errors.proID && formProps.touched.proID,
                              })}
                              disabled ={is_disabled}
                        />
                {is_query &&<>
                          <p className="help">Search the archive by program ID e.g., A/2018B/01</p>
                          <p className="help">This Feaure is experimental</p>
                          <p className="help is-danger"><ErrorMessage name="proID"/></p></>}
                </div>}
                {[{field_id:'ra',helptext:'Right Ascension in degrees; 10.2345.',type:'text',field_name:'RA'},
                {field_id:'dec',helptext:'Declination in degrees; -0.2716.',type:'text',field_name:'Dec'},
                {field_id:'radius',helptext:'Maximum value = 3600". Warning values above 300" will take some time..',type:'text',field_name:'Radius (arcsec)'},
                {field_id:'start_date',helptext:'The start date of the observation. This should be entered as yyyy-mm-dd',type:'text',field_name:'Start date'},
                {field_id:'end_date',helptext:'Enter a date (after the start date). This should be entered as yyyy-mm-dd',type:'text',field_name:'End date'}].map(
                    field=>!(is_query===false&&initialValues[field.field_id]===undefined)&&
                    <div className="field">
                    <label htmlFor={field.field_id} className="label">{field.field_name}</label>
                          <Field
                              name={field.field_id}
                              type={field.type}
                              className={clsx('input ', {
                                'is-danger': formProps.errors[field.field_id] && formProps.touched[field.field_id],
                              })}
                              disabled ={is_disabled}
                          />
                    {is_query &&<>
                          <p className="help">{field.helptext}</p>
                          <p className="help is-danger"><ErrorMessage name={field.field_id}/></p>
                          </>}
                </div>
                )}
                {is_query===false&&initialValues['instrument']!==undefined&&
                <div className="field">
                    <label htmlFor="instrument" className="label">Instrument</label>
                    <Field
                          name="instrument"
                          type='text'
                          className={clsx('input ', {
                            'is-danger': formProps.errors.instrument && formProps.touched.instrument,
                          })}
                          disabled ={is_disabled}
                      />
                </div>}
                {is_query===false&&initialValues['obstype']!==undefined&&
                <div className="field">
                    <label htmlFor="obstype" className="label">Observation Type</label>
                    <Field
                          name="obstype"
                          type='text'
                          className={clsx('input ', {
                            'is-danger': formProps.errors.obstype && formProps.touched.obstype,
                          })}
                          disabled ={is_disabled}
                      />
                </div>}

                {is_query&&
                <div className="field">
                          <label htmlFor="instrument" className="label">Instrument</label>
                          <div className="select">
                            <Field
                                name="instrument" as="select"
                                onChange={handleInstrumnetChange}
                                value={initialValues.instrument}
                                disabled ={is_disabled}
                            >
                              <option value="">Select</option>
                              {config.instruments.map(item => getOption(item, item))}
                            </Field>
                          </div>
                          {is_query &&<>
                          <br/>
                          <p className="help is-danger"><ErrorMessage name="instrument"/></p></>}
                </div>}
                {is_query&&
                        <div className="field">
                          <label htmlFor="obstype" className="label">Observation Type</label>
                          <div className="select">
                            <Field
                                name="obstype" as="select"
                                disabled ={is_disabled}
                            >
                              <option value="">Select</option>
                              {obs_type.map(item => getOption(item.value, item.name))}
                            </Field>
                          </div>
                          {is_query&&<>
                          <p className="help">Type of observation - RUN, ARC, FLAT etc. Unfortunately,
                            most observations are called RUN even if they are not. Only useful after about 1998.</p>
                          <p className="help is-danger"><ErrorMessage name="obs_type"/></p></>}
                        </div> }
                        {is_query&&
                        <div className="m-t-md m-b-lg">
                          {!formProps.isValid && <div className="notification is-danger is-light">Form has errors, please scroll up and make changes before re-submitting.</div>}
                          
                          <Button
                              type="submit"
                              color={formProps.isSubmitting ? "secondary" : "primary"}
                              variant="contained"
                              disableElevation
                              disabled={formProps.isSubmitting}
                          >
                            {formProps.isSubmitting ? "Querying database..." :"Query"}
                            {formProps.isSubmitting && <CircularProgress size={24} style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: -12,
                              marginLeft: -12,
                            }}/>}
                          </Button>
                          {showResetQuestion ?
                              <div style={{float: 'right'}} className="box p-xs m-b-md">
                                <p className="help m-none">Are you sure you want to clear the form?</p>
                                <Button type="reset" color="primary" variant="outlined" onClick={() => {
                                  formProps.resetForm();
                                  handleToggleResetQuestion(false)
                                }}>
                                  Yes
                                </Button>
                                <Button type="reset" onClick={() => {
                                  handleToggleResetQuestion(false)
                                }}>
                                  cancel
                                </Button>
                              </div>
                              : <Button type="reset" variant="outlined" onClick={() => {
                                handleToggleResetQuestion(true)
                              }} style={{float: 'right'}}>
                                Clear Form
                              </Button>
                          }
                        </div>}
                      </Form>
                  );
                }}
    </Formik>
  );
}