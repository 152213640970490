import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PetsIcon from '@mui/icons-material/Pets';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{props.rowNumber}(Loading)/{props.rowCount}(Total)</Typography>
        </Box>
      </Box>
    );
  }

export function ResultsTableToolbar  (props)  {
    /***
     *
     */
    const {selected, rowCount, isDownloading,proprietarySelectedCount,isReducing,is_query_finished,rowNumber} = props;
    let numSelected = selected === undefined ? 0 : selected.length;
    return (
        <div className="notification is-primary is-light">
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <div className="has-text-black">
                            {numSelected}/{rowCount} selected. {proprietarySelectedCount > 0 ? `${proprietarySelectedCount} are proprietary and will not be downloaded.` : ""}
                            {/* {is_query_finished&&<>Total {rowCount}</>} */}
                        </div>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <Button
                            color={isDownloading ? "secondary" : "primary"}
                            variant="outlined"
                            disableElevation
                            disabled={!numSelected || isDownloading}    
                            onClick={props.handleDownload}
                            style={{textTransform: 'capitalize'}}>
                            {isDownloading ? "Downloading..." : "Download files"}
                            {isDownloading && <CircularProgress size={24} style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                            }}/>}
                        </Button>
                        {/* *******User need to Login to Get the Reduced Pannel**********/}
                        {!props.user&&process.env.REACT_APP_API_DOWNLOAD_PUDDING_ENABLE==='true'&&
                        <Button
                            color={"secondary" }
                            variant="outlined"
                            disableElevation
                            disabled={true}    
                            onClick={() =>console.log('click')}
                            style={{textTransform: 'capitalize'}}>
                            { "Login to Data Central to Reduce with 2dFdr PAWS"}
                        </Button>}
                        {props.user&&process.env.REACT_APP_API_DOWNLOAD_PUDDING_ENABLE==='true'&&
                        <Button
                            color={isReducing ? "secondary" : "primary"}
                            variant="outlined"
                            disableElevation
                            disabled={!numSelected || isReducing}    
                            onClick={props.handleDownloadpudding}
                            startIcon={<PetsIcon/>}
                            style={{textTransform: 'capitalize'}}>
                            {isReducing ? "Downloading..." : "Reduce with 2dFdr PAWS"}
                            {isReducing && <CircularProgress size={24} style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                            }}/>}
                        </Button>
                        }
                    </div>
                </div>
            </div>
            <div>
            {/* {!is_query_finished&&
            <LinearProgressWithLabel rowNumber={rowNumber} rowCount={rowCount} value={rowNumber*100/rowCount} />} */}
            </div>
        </div>
    );
};


