import React from 'react';
import {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createTheme, StyledEngineProvider} from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import QueryPage from './components/QueryPage';
import QueryResults from './components/QueryResults';
import LogBook from './components/LogBook';
import PrimaryNav from './components/PrimaryNav';
import './App.css';

const theme = createTheme(({
    palette: {
        primary: {
            main: '#192031',
        },
        secondary: {
            main: '#ddd',
        },
    },
}));

const App = () => {
    const [user, setUser] = useState(false);

   
        return (
            <Router>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                    <PrimaryNav user={user} setUserName={setUser} /> 
                    <Header />
                    <Switch>
                        <Route path="/results/:query_id?">
                            <QueryResults user={user} />
                        </Route>
                        <Route path="/about">
                            {/* <About /> */}
                        </Route>
                        <Route path="/query/:query_id">
                            <QueryResults user={user} query={true}/>
                        </Route>
                        <Route path="/query">
                            <QueryPage user={user}/>
                        </Route>
                        <Route path="/logbook">
                            <LogBook user={user}/>
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                    <Footer />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Router>
        );

    
}

export default App;