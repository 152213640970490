


const CREDITL_DEFAULT='omit'



export const readfromcookie=(user,CREDITL=CREDITL_DEFAULT)=>{

	let iscredential=CREDITL
	let response={'header':{"Content-Type": "application/json"},'credentials':iscredential}
	if(user === false||user==='') return response;
	
	if(document.cookie === undefined) return response;

  const tokens = document.cookie.split("; ");
  const csrftokens=tokens.filter(token=>token.indexOf('csrftoken')!==-1);

	if(csrftokens.length<1) return response;
  iscredential='include'
	const csrftoken=csrftokens[csrftokens.length-1].split('=')[1]

	response={'header':{"Content-Type": "application/json",'X-CSRFToken': csrftoken},'credentials':iscredential}
	return response;
	}


const gettrimdata = (data) =>{
  const trimmed_data ={}
  if(data.hasOwnProperty('obsDate')){
    if (data['obsDate']!==''){
        trimmed_data['obs_date'] = data['obsDate'];
        return trimmed_data; //for lgobook
      }
  }
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== ""){
        trimmed_data[key] = data[key]
      }
    }
  }
  return trimmed_data
}

export const downrequest = async (data,user=null) =>{
  const requestdata=readfromcookie(user);
  const headers =requestdata.header ;
  headers['Accept'] = "application/json";
  headers['Origin'] = "";

  const trimmed_data = gettrimdata(data)

  // remove empty fields from data (else the api looks for matches to blank strings)

  console.log('downloadQuery', trimmed_data)
  const host=window.location.protocol + "//" + window.location.hostname
  const res = await fetch(host + process.env.REACT_APP_API_DOWNLOAD, {
    method: 'POST',
    headers: headers,
    credentials: requestdata.credentials,
    body: JSON.stringify(trimmed_data),
  })

  return res;
}

export const downrequest_pudding  = async (data,user=null) =>{
  const requestdata=readfromcookie(user);
  const headers =requestdata.header ;
  headers['Accept'] = "application/json";
  headers['Origin'] = "";

  const trimmed_data = gettrimdata(data)
  console.log('downloadQueryPudding', trimmed_data)
  // remove empty fields from data (else the api looks for matches to blank strings)
  const host=window.location.protocol + "//" + window.location.hostname
  const res = await fetch(process.env.REACT_APP_API_DOWNLOAD_PUDDING, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(trimmed_data),
  })
  return res;
}

export const queryrequest_all  = async (data,user=null) =>{
  const requestdata=readfromcookie(user);
  const headers =requestdata.header ;
  headers['Accept'] = "application/json";
  headers['Origin'] = "";

  const trimmed_data = gettrimdata(data)

  // remove empty fields from data (else the api looks for matches to blank strings)
  const host=window.location.protocol + "//" + window.location.hostname
  const res = await fetch(host + process.env.REACT_APP_API_URL, {
    method: 'POST',
    headers: headers,
    credentials: requestdata.credentials,
    body: JSON.stringify(trimmed_data),
  })
  return res;
}

export const sendrequest = (data, responsequery,functionerror,user=null) =>{
  const requestdata=readfromcookie(user);
  const headers =requestdata.header ;
  headers['Accept'] = "application/json";
  headers['Origin'] = "";

  const trimmed_data = gettrimdata(data)
  const host=window.location.protocol + "//" + window.location.hostname

  // remove empty fields from data (else the api looks for matches to blank strings)

  console.log('submitQuery', trimmed_data)

  fetch(host + process.env.REACT_APP_API_URL, {
    method: 'POST',
    headers: headers,
    credentials: requestdata.credentials,
    body: JSON.stringify(trimmed_data),
  }).then((response) => {
      if(response.status===400) {console.log(response.json()); throw new Error('Error: 400');}
      if(response.status===200||response.status===202|| response.status===304 )return response.json();
      })
      .then((responseJson) => {
        responsequery(responseJson)
      })
      .catch((error) => {
        if(error==='TypeError: Failed to fetch') console.error('There is some issue with server, Please try it later or contact admin');
        else if(error==='Error: 400') functionerror(error)
        else functionerror(error);
      })
}

export const submitQuery = (data, history, dispatch,user,functionerror,setSubmitting) => {
  
  const requestdata=readfromcookie(user);
  const headers =requestdata.header ;
  headers['Accept'] = "application/json";
  headers['Origin'] = "";

  const trimmed_data = gettrimdata(data)

  // remove empty fields from data (else the api looks for matches to blank strings)
  const host=window.location.protocol + "//" + window.location.hostname
  console.log('submitQuery', trimmed_data)
  try{
  fetch(host + process.env.REACT_APP_API_URL, {
    method: 'POST',
    headers: headers,
    credentials: requestdata.credentials,
    body: JSON.stringify(trimmed_data),
  }).then((response) => {

        if(response.status===400) { functionerror('Error: 400');throw new Error('Error: 400');}
        if(response.status===401) {functionerror('Error: 401'); throw new Error('Error: 401');}
        if(response.status===200||response.status===202|| response.status===304 ) return response.json();
        else{
          alert('There is some issue with server, Please try it later or contact admin');
          throw new Error('TypeError: Failed to fetch');
        }
      
        
      })
      .then((responseJson) => {
        dispatch({
          type: 'RESULTS',
          payload: responseJson
        });
        try{
          history.push({
          pathname:  "/results/"+responseJson[0]['uuid'],})
        }catch{
          alert('There is some issue with server, Please try it later or contact admin');
          throw new Error('TypeError: Failed to fetch');
        }
        
        
      })
      .catch((error) => {
        if(error==='TypeError: Failed to fetch') console.error(error);
        if(error==='Error: 400') console.error('Error: 400');
        if(error==='Error: 401') console.error('Error: 401');
        setSubmitting(false)
      })
    }catch(error){
        console.log('error')
        console.error(error)
        alert('There is some issue with server, Please try it later or contact admin');
      }
}
