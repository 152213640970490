import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import ErrorBoundary from './ErrorBoundary'
import CellModalContent from "./CellModalContent";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

class CellModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            title: props.title,
            open: false,
        }
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClickOpen() {
        this.setState({open: true});

    };

    handleClose() {
        this.setState({open: false});
    };


    render() {
        const {value, title, open} = this.state;
        const {classes} = this.props;

        return (
            <div>
                <Button onClick={this.handleClickOpen} color="primary" size="small" variant="outlined">View</Button>

                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <MuiDialogTitle disableTypography id="scroll-dialog-title" className={classes.root}>
                        <Typography variant="h6">{title}</Typography>
                        {open ? (
                            <IconButton
                                aria-label="close"
                                onClick={this.handleClose}
                                className={classes.closeButton}
                                size="large">
                                <CloseIcon/>
                            </IconButton>
                        ) : null}
                    </MuiDialogTitle>

                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            // ref={descriptionElementRef}
                            tabIndex={-1}
                            component="span"
                        >
                            <ErrorBoundary>
                                <CellModalContent value={value} title={title}/>
                            </ErrorBoundary>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );

    }
}

export default withStyles(styles)(CellModal);

CellModal.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]).isRequired,
    title: PropTypes.string.isRequired
};