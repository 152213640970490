import React from 'react';
import PropTypes from 'prop-types';
import download from 'downloadjs';
import clsx from 'clsx';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import CellModal from './CellModal'
import {sendrequest,downrequest,downrequest_pudding,queryrequest_all} from '../services/requestsetting'
import QueryTable from './QueryTable';
import {ResultsTableToolbar} from './ToolBar';

function getTextWidth(longestCellValueDict) {
    /***
     * Create a canvas element in memory to render the
     * longest string in each value of maxCharDict, in
     * order to grab the element width in px. Note we add
     * 50px padding to account for browser rendering differences.
     * @type {{}}
     */
    const maxWidthDict = {}
    const minWidthPx = 150
    Object.keys(longestCellValueDict).forEach(function (key) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext("2d");
        ctx.font = "16px Arial";

        const width = ctx.measureText(longestCellValueDict[key]).width;
        if (width > minWidthPx) {
            maxWidthDict[key] = width + 50
        } else {
            maxWidthDict[key] = minWidthPx
        }
    });

    return maxWidthDict
}

function sanitizeData(rows, maxWidthDict,permission_list) {
    /***
     * Sanitize data (this probably should be done server-side) rows=[{a: 1, b: {}...},{a: 0, b:{}...}...]
     * Replace empty objects with blank strings
     */
    
    if (typeof rows !== 'undefined' && rows.length > 0) {
        rows=rows.filter(row=>row.aat_id!=null) //filter to remove the record with no aat_id items
        // the array is defined and has at least one element
        const longestCellDict = {}                                  // max number of characters in each column
        Object.keys(rows[0]).forEach(function (key) {
            longestCellDict[key] = key;                             // start with the key (column header)
        });

        rows.map(function (row, i) {                                // iterate over arr (each element is obj)
            for (const [key, value] of Object.entries(row)) {       // for each value in obj
                if (typeof value === 'object' && value !== null) {  // if the value itself is obj
                    if (Object.keys(value).length === 0) {          // and that obj is empty
                        row[key] = '';                              // set it to blank string
                    }
                }
                // if the length of this cell value is longer than the stored value, replace
                if (typeof value === 'string' || typeof value === 'number') {
                    if(longestCellDict[key]!==undefined){
                        if (longestCellDict[key].length < value.toString().length) {
                        longestCellDict[key] = value.toString()
                        }
                    }
                    
                }
            }
            // calculate whether row is proprietary or not
            row['isProprietary'] = isInProprietaryPeriod(row['obs_date'],permission_list, row['fits_header']['OBSTYPE'],row['ProgramID'])
            return row
        })
        maxWidthDict = getTextWidth(longestCellDict)
    }
    return {rows, maxWidthDict}
}

const isInProprietaryPeriod = (obs_date,permission_list, OBSTYPE = 'OBJECT',programID) => {
    /***
     * Use the observing date and the OBSTYPE to define whether the proprietary period is in force.
     * If there's any error, assume within the proprietary period. Similarly, if the OBSTYPE cannot be found,
     * assume it's an OBJECT.
     * and return true
     */
    // calibration files are not proprietary
    
    if (OBSTYPE !== 'OBJECT') {
        return false;
    }
    // add to check the prgram ID permission
    if(programID!=='' && permission_list.length>0 &&  permission_list.includes(programID)) 
        return false;

    try {
        const today = new Date();
        const obsDate = new Date(obs_date)
        today.setMonth(today.getMonth() - 18);
        return obsDate >= today;
    } catch {
        // if we can't parse the date, assume proprietary
        return true
    }
}



const functionerror = () =>{

}

const setcolumns = (rows,maxWidthDict=null) =>{
    let columns = []
    const DefaultColumns =["aat_id",
    "fits_header",
    "ProgramID",
    "ndf_class",
    "OBJECT" ,
    "ra",
    "dec",
    "EXPOSED",
    "instrument",
    "GRATID",
    "DICHROIC",
    "detector",
    "obs_date",
    "targets",
    "fibre_table",
    "obscore",
    "topend",
    "telescope_metadata",
    "filename","isProprietary"] //Object.keys(rows[0]).filter(key => key !== '_id')
        if (typeof rows !== 'undefined') {
            try {
                columns = DefaultColumns.map(item => ({
                    field: item,
                    headerName: item,
                    width:maxWidthDict!==null&&maxWidthDict!==undefined?maxWidthDict[item]:150,
                    minWidth:100,
                    sortable: item.toString() !== 'obscore', //todo remove from all object-type columns
                    filterable: item.toString() !== 'obscore',
                    disableClickEventBubbling: true,
                    renderCell: (params) => (
                        // if item is dictionary and not empty, show a modal dialog with a cell
                        // capable of displaying a tabular version of the contents.
                        <div className={params.row.isProprietary ? "has-background-danger-light" : ''}>
                            {typeof params.value === "object" && params.value !== null ?
                                <CellModal
                                    value={params.value}
                                    title={item.toString()}/>
                                : params.value}
                        </div>
                    ),
                    hide: ['filename', 'isProprietary'].includes(item.toString()),
                    cellClassName: (params) =>
                        clsx({
                            'has-background-danger-light': params.row.isProprietary,
                        }),
                }))
            } catch (e) {
                console.error('error loading colums',e)
            }
        }
    return columns
}

export default class ResultsTable extends React.Component {
    /***
     *
     */
    constructor(props) {
        super(props);
        this.state = {
            selectionModel: [],
            isDownloading: false,
            isReducing:false,
            rows: [],
            columns: setcolumns([]),
            loading:false,
            maxWidthDict: {},
            proprietarySelectedCount: 0,
            rowCount:0,
            requestparam:{
                uuid:'',
                next:'',
                previous:''},
            page:1,
            query_id:this.props.query_id,
            query:null,
            is_open:false,
            is_query_finished:false
        }
        this.handleDownload = this.handleDownload.bind(this);
        this.handleDownloadpudding =  this.handleDownloadpudding.bind(this);
        this.handleSetSelectionModel = this.handleSetSelectionModel.bind(this);
        this.handleSetIsDownloading = this.handleSetIsDownloading.bind(this);
        this.handlesetResult =this.handlesetResult.bind(this);
        this.handlesetpage = this.handlesetpage.bind(this);
        this.responsequery = this.responsequery.bind(this);
        this.querydataquery = this.querydataquery.bind(this)
        this.handleTabHide = this.handleTabHide.bind(this)
        this.query_all_data = this.query_all_data.bind(this)
    }

    componentDidMount() {
        /**
         * Sanitize the data and define the columns on component from props and set on local state.
         * The incoming data, once loaded, does not change.
         */
         console.log('initial')
        if(this.props.query_id!==undefined) {
            this.setState({requestparam:{uuid:this.props.query_id},loading:true})
            // get query detail
            sendrequest({uuid:this.props.query_id,request:true}, this.querydataquery,functionerror,this.props.user)
            //get first 100 rows
            sendrequest({"uuid":this.props.query_id}, this.responsequery,functionerror,this.props.user)
            
        }else{
            let resultdata=this.handlesetResult(this.props.data)
            let {rows, maxWidthDict} = sanitizeData(resultdata.results,{},resultdata.permission_list)
            const columns=setcolumns(rows, maxWidthDict)
            this.setState({rows: rows, maxWidthDict: maxWidthDict, columns: columns});
        }

    }

    handlesetpage(newpage){
        this.setState({page:newpage.page})
        if(this.state.rowCount>this.state.rows.length)
            sendrequest(this.state.requestparam, this.responsequery,functionerror,this.props.user)
    }

    query_all_data = async () =>{
        
        if(this.state.rowCount>this.state.rows.length){
            try{
                const response = await queryrequest_all(this.state.requestparam)
                const data= await response.json()
                if(response.status===200||response.status===202|| response.status===304){
                    this.responsequery (data)
                }
           }catch(error){
               console.error(error)
           }
           console.log('loading data ....'+ this.state.rows.length)
        }else{
            this.setState({is_query_finished:true})
            console.log('finish data '+ this.state.rows.length)
        }
        
    }

    responsequery (data,is_continue=true){

        const resultdata=this.handlesetResult(data)
        let {rows, maxWidthDict} = sanitizeData(resultdata.results,{},resultdata.permission_list)
        if(this.state.columns.length===0){
            
            this.setState({columns:setcolumns(rows, maxWidthDict),rows: [...this.state.rows,...rows], maxWidthDict: maxWidthDict,loading:false});
        }else{
            this.setState({rows: [...this.state.rows,...rows], maxWidthDict: maxWidthDict,loading:false});
        }
        if(is_continue) this.query_all_data() 
    }

    querydataquery(data){
        
        const dataquery=data[0].query
        if(dataquery.obs_date!==undefined){
          dataquery['start_date'] = dataquery.obs_date
          dataquery['end_date'] = dataquery.obs_date
        }
        const query= {...this.state.querydata,...dataquery}
        this.setState({query:query})
    }

    handlesetResult(data){

        try{
            let rows=JSON.parse(JSON.stringify(data))
            if(rows.length>0) { 
                this.setState({rowCount:rows[0].count,query_id:rows[0].uuid,requestparam:{uuid:rows[0].uuid,next:rows[0].next['aat_id'],previous:rows[0].next['aat_id']}})
                rows=rows[0] /***to get result and if there is no data show empty result table***/
            } 
            return rows;
         }catch (error) {
            
            return {results:[],permission_list:[]};
        }
        
        
    }

    handleSetSelectionModel(selectionModel) {
        /**
         * Set the selection model on state, and increase counter if any are proprietary
         * @type {number}
         * max slected number 25
         */
        let proprietarySelectedCount = 0
        const MAXSelected=100
        const selected=selectionModel!==undefined?selectionModel.slice(0,MAXSelected):selectionModel
        this.state.rows.forEach(row => {
                if ('aat_id' in row) {
                    if (selected.includes(row["aat_id"]) && row["isProprietary"]) {
                            proprietarySelectedCount += 1

                    }
                }
            }
        );
        
        this.setState({selectionModel:selected, proprietarySelectedCount: proprietarySelectedCount});
        
    }


    handleSetIsDownloading(isDownloading) {
        this.setState({isDownloading: isDownloading});
    }
    handleTabHide(){
        this.setState({is_open:!this.state.is_open})
    }

    handleDownload = async () => {
        /***
         *
         */
        this.handleSetIsDownloading(true)

        // Call the api with set of ids and ask backend to send a list of files. If there
        // are more than 1 file, zip it.
        alert("It will take time to download files, please do not refresh or close the page ");
        const selected_paths = []
        const aat_ids=[]
        this.state.selectionModel.forEach(element => {
            for (const row of this.state.rows) {
                // don't push if proprietary
                if (row['aat_id'].toString() === element.toString() && !row['isProprietary']) {
                    selected_paths.push(row['filename'])
                    aat_ids.push(row['aat_id'])

                    break;
                }
            }
        });

        const res = await downrequest({'aat_ids':aat_ids});
        const blob = await res.blob();
        if (res.status !== 200) {
            alert("Files cannot been downloaded ");
            this.handleSetIsDownloading(false);
            return;
        } else {
            this.handleSetIsDownloading(false);
        }

        // if single file, use that filename! Otherwise assume gzip
        let download_name = 'aat_archive_download.gz'
        // if (selected_paths.length === 1) {
        //     download_name = selected_paths[0].split('/')[selected_paths[0].split('/').length-1]
        // }
        download(blob, download_name);

    }

    handleDownloadpudding = async () => {
        /***
         * To send request to puddig and get response
         */
        this.setState({isReducing:true})
        const aat_ids=[]
        this.state.selectionModel.forEach(element => {
            for (const row of this.state.rows) {
                // don't push if proprietary
                if (row['aat_id'].toString() === element.toString() && !row['isProprietary']) {
                    aat_ids.push(row['aat_id'])
                    break;
                }
            }
        });
        //telescope is hardcode to aat_archive, will be changed in the future. this is temporary for testing, change it later
        
        const res = await downrequest_pudding({'aat_ids':aat_ids,"username":this.props.user||'bmiszalski',"telescope":'aat_archive'});
        if (res.status !== 200) {
            alert("2dFdr PAWS system offline. Please try again later or contact Data Central");
            this.setState({isReducing:false});
            return;
        } else {
            const json=await res.json();
            try{
                if(json.status==='ok') window.open(json.url, '_blank').focus(); 
                else {
                    alert(json.status_msg);
                }
            }catch{
                alert("Please enbale the pop up window for this tab")
            }
            
            //console.log(json)
            //alert("Files start downloading, you should receive email soon about the detail information");
            this.setState({isReducing:false});
        }
    }

    render() {
        const {selectionModel, isDownloading, rows, columns, proprietarySelectedCount,rowCount,isReducing} = this.state;
        let link= window.location.href.split('/').slice(0, 3).join('/')
        const resultlink=link+'/results/'+this.state.query_id
        return (
            <div className="container" >
                    <div className="message">
                        <div className="message-body">
                            <p>An 18 month proprietary period is in force. Data less than 18 months old can only be
                                distributed
                                to a member of the observing team.&nbsp;
                                <a href="https://jira.aao.org.au/servicedesk/customer/portal/3"> Contact us</a> if you are
                                a member of the observing team and would like to retrieve proprietary data.
                            </p>
                            <p>Proprietary rows are downloadable as part of the csv export functionality, but you cannot
                                download the fits files themselves (note that there is no proprietary period for calibration
                                files).
                                Data that is proprietary is marked with a <span className="has-background-danger-light"> red background </span>.
                            </p>
                            <br/>
                            <p> After logging into Data Central via the Login button (Top Right), users can reduce selected 2dF-AAOmega data via the '<a href="https://docs.datacentral.org.au/reference/services/pipeline-as-a-web-service-paws/" rel="noopener noreferrer" target="_blank">Reduce with 2dFdr PAWS</a>' button. </p>
                            <p> No need to selection calibration data, only MFOBJECT (science) data.</p>
                            <br/>
                            {this.state.query_id!==undefined&& <p>Share this result: <a href={resultlink}> {resultlink}</a></p>}
                        </div>
                    </div>
                <QueryTable
                    is_query={false}
                    initialValues={this.state.query}
                    showResetQuestion={false}
                    is_open={this.state.is_open}
                    handleTabHide={this.handleTabHide}
                />
               
                {columns.length>5 && rows.length>0 &&
                <ResultsTableToolbar selected={selectionModel} 
                    rowNumber={rows.length}
                    handleDownload={this.handleDownload}
                    handleDownloadpudding={this.handleDownloadpudding}
                    user={this.props.user}
                    isReducing={isReducing}
                    isDownloading={isDownloading} 
                    proprietarySelectedCount={proprietarySelectedCount}
                    is_query_finished = {this.state.is_query_finished}
                    rowCount={this.state.rowCount}
                                     />
                }
                <div style={rows.length > 0 ? {height: 800, width: '100%'} : {height: 300, width: '100%'}}>
                {columns.length<5?
                    <CircularProgress size={24} style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                    }}/>
                    :
                   <DataGrid
                        scrollbarSize={1}
                        rows={rows}
                        getRowId={(row) => row.aat_id}
                        columns={columns}
                        initialState={{
                            pagination: {
                              pageSize:10
                            },
                          }}
                        rowsPerPageOptions={[10, 25]}
                        checkboxSelection
                        rowCount={rowCount}
                        onSelectionModelChange={(newSelection) => {
                            this.handleSetSelectionModel(newSelection);
                        }}
                        loading={this.state.loading}
                        onPageChange={(newPage) => this.handlesetpage(newPage)}
                        // onSelectionChange={newSelection => {console.log(newSelection); this.handleSetSelectionModel(newSelection.rowIds)}}
                        selectionModel={selectionModel}
                        components={rows.length > 0 ? {
                            Toolbar: GridToolbar,
                        }: {}} 
                        sx={{
                            "& .MuiDataGrid-virtualScroller": {
                              overflowX: "scroll"
                            },
                            
                          }}        
                    />}
                </div>
            </div>
        );
    }

}


ResultsTableToolbar.propTypes = {
    selected: PropTypes.array.isRequired,
};
