import React from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import {NavLink, withRouter} from 'react-router-dom';
import * as Yup from 'yup';
import {AppContext} from '../reducers/store';
import clsx from "clsx";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {submitQuery} from '../services/requestsetting'

// To submit a form, user has to fire off either handleSubmit(e) or submitForm props.



class LogBook extends React.Component {

    

    constructor(props) {
    super(props);
    this.functionerror= this.functionerror.bind(this)
   
    }

    componentDidMount() {
        this.context.dispatch({
            type: 'RESULTS',
            payload: []
        });
    }

    functionerror (error) {
        if(error==='Error: 400') alert('Please set valid Date, Data less than 18 months old can only be distributed to a member of the observing team')

    }

    render() {
        return <React.Fragment>
            <div className="container is-widescreen">
                <nav className="breadcrumb is-right p-r-none has-bg-white" aria-label="breadcrumbs"
                     style={{display: 'block'}}>
                    <ul>
                        <li><NavLink exact={true} activeClassName='is-active' to='/'>Home</NavLink></li>
                        <li><NavLink activeClassName='is-active' to='/logbook'>Logbook</NavLink></li>
                    </ul>
                </nav>
                <h4 className="title is-4 ">Access the AAT Logbook</h4>
                <h4 className="subtitle is-6 has-text-grey">Search for entries in the AAT Logbook</h4>
                <hr style={{borderTop: '2px solid #f5f5f5'}}/>
                <div className="columns m-t-md" style={{minHeight: '60vh'}}>
                    <div className="column is-5">
                        <div className="box has-bg-light">
                            <Formik
                                initialValues={this.context.state.logbook}
                                validationSchema={Yup.object({
                                    obsDate: Yup.string()
                                        .required('Required')
                                })}
                                onSubmit={(values,{setSubmitting}) => {
                                    console.log("query submitted.")
                                    this.context.dispatch({
                                        type: 'LOGBOOK',
                                        payload: values
                                    });
                                    submitQuery(values, this.props.history, this.context.dispatch, this.props.user, this.functionerror,setSubmitting);
                                }}
                            >{
                                formProps => {
                                    return (
                                        <Form>
                                            <div className="field">
                                                <label htmlFor="obsDate" className="label">Observation Date</label>
                                                <Field
                                                    name="obsDate"
                                                    type="text"
                                                    className={clsx('input ', {
                                                        'is-danger': formProps.errors.obsDate && formProps.touched.obsDate,
                                                    })}
                                                />
                                                <p className="help">Enter a single date only. This should be entered as
                                                    yyyy-mm-dd.</p>
                                                <p className="help is-danger"><ErrorMessage name="obsDate"/></p>
                                            </div>
                                            <div className="m-t-md m-b-lg">
                                                {formProps.isValid ? '' :
                                                    <div className="notification is-danger is-light">Form has errors,
                                                        please make changes before re-submitting.</div>}
                                                <Button
                                                    type="submit"
                                                    color={formProps.isSubmitting ? "secondary" : "primary"}
                                                    variant="contained"
                                                    disableElevation
                                                    disabled={formProps.isSubmitting}
                                                >
                                                    {formProps.isSubmitting ? "Submitting" : "Submit"}
                                                    {formProps.isSubmitting && <CircularProgress size={24} style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: -12,
                                                        marginLeft: -12,
                                                    }}/>}
                                                </Button>
                                            </div>
                                        </Form>)
                                }}
                            </Formik>
                        </div>
                    </div>
                    <div className="column is-7">
                        <div className="content box">
                            <p>
                                Some of the fields that are displayed on the log pages will be absent for data
                                prior to 1999. Such items were not recorded with the data before the current electronic
                                log system started. Some fields (e.g. seeing) are added by the night assistant during
                                observations, while others represent improved computer control of the instrument.
                                In general - the older the data, the less information that will be available. In which
                                case,
                                the original hand-written logs will need to be consulted. Request these to be sent to
                                you
                                when requesting any data.
                            </p>
                            <p>The fields reported on the log are not user-configurable but assigned for each
                                instrument.
                                If you believe that there are reasons for having a different layout, having other fields
                                displayed or perhaps fields removed, then <a
                                    href="https://jira.aao.org.au/servicedesk/customer/portal/3">get in touch</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

LogBook.contextType = AppContext;
export default withRouter(LogBook)