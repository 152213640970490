
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Nav() {

    return (
        <nav className="breadcrumb is-right p-r-none has-bg-white" aria-label="breadcrumbs"
                 style={{display: 'block'}}>
                <ul>
                    <li><NavLink exact={true} activeClassName='is-active' to='/'>Home</NavLink></li>
                    <li><NavLink activeClassName='is-active' to='/query'>Query</NavLink></li>
                    <li><NavLink activeClassName='is-active' to='/results'> Results</NavLink></li>
                </ul>
            </nav>
    )
}