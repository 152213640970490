import React from 'react';
import {useState} from 'react';
import { useLocation } from 'react-router-dom';
import {readfromcookie} from '../services/requestsetting'


export default function Loginc({username,setUserName}) {
	const location = useLocation()

	const [user, setUser] = useState(username);
	const host=window.location.protocol + "//" + window.location.hostname
	const loginURL=host + process.env.REACT_APP_Token_OBTAIN+'au/login/?next='+location.pathname
	const logoutURL=host+ process.env.REACT_APP_Token_OBTAIN+'auth/accounts/logout/'

	
 
	const handleaccess = () =>{

		const data=readfromcookie('test')

		sendrequesttest('user/',getuserinfo,data);


	}

	const getuserinfo = (data) =>{
		if(data.user!==undefined||data.user!=='') 
		{
			setUser(data.user);
			setUserName(data.user)
		};
	}

	const sendrequesttest = (requestlink,functioncallback,data) => {
		const headers = data.header;
	  headers['Accept'] = "application/json";
	  headers['Origin'] = "";

	  	const host=window.location.protocol + "//" + window.location.hostname
		fetch(host+process.env.REACT_APP_Token_OBTAIN+requestlink, {
			credentials:'include',
	    method: 'POST',
	    headers: headers,
	    // body: JSON.stringify(trimmed_data),
  	}).then((response) => {
        return response.json()
      })
      .then((responseJson) => {
      	functioncallback(responseJson)
		}).catch(function(err) {
			console.error(err);
			console.error('error for request user')
		});
	}


	handleaccess()


  return (
      <>
      	{user?(
      		<div className="navbar-item has-dropdown is-hoverable">
      			<a className="navbar-link has-text-primary is-arrowless" href="#/">
      				<strong>{user}<i className="m-l-xs m-t-xxs fas fa-caret-down"></i></strong>
            </a>
            <div className="navbar-dropdown">
            	<a className="navbar-item" href={logoutURL}>Log out</a>
            </div>       
          </div>
          )
          :
        (<div className="navbar-item ">
        	<div className="buttons">
              <a className="button is-primary " href={loginURL}>Login</a>
          </div>
        </div>)
    	}
        
      
    </>
  );
}