import React from 'react';
import { NavLink } from 'react-router-dom';


export default function Header() {

  return (
    <React.Fragment>
      {/*<CssBaseline />*/}
        <nav className="navbar is-primary p-none arch" role="navigation" aria-label="main navigation">
            <div className="container is-widescreen p-none">
                <div className="navbar-brand">
                    <div role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false"
                       data-target="navBarSecondary" style={{marginRight: 'auto', marginLeft: '0'}}>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </div>
                </div>
                <div id="navBarSecondary" className="navbar-menu">
                    <div className="navbar-start">
                        <NavLink exact={true} activeClassName='is-active' to='/' className="navbar-item has-text-weight-bold">Home</NavLink>
                        <NavLink activeClassName='is-active' to='/query' className="navbar-item has-text-weight-bold">Query</NavLink>
                        <NavLink activeClassName='is-active' to='/logbook' className="navbar-item has-text-weight-bold">Log Book</NavLink>
                    </div>
                </div>
            </div>
        </nav>
      </React.Fragment>
  );
}
