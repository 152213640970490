const config = {
    instruments : [
        "2dF+AAOMEGA",
        "2dF+HERMES",
        "KOALA",
        "Veloce",
        "di",
        "Spector/Hector",
        "cfi",
        "rgo25",
        "rgo82",
        "tfp",
        "frgo25",
        "manech",
        "fors",
        "ucles",
        "figs",
        "argo25",
        "f1",
        "afi",
        "ldss",
        "frgo82",
        "tdi",
        "wprgo25",
        "wprgo82",
        "iris1i",
        "iris1s",
        "fucles",
        "sempol",
        "wpt",
        "argo82",
        "hatpol",
        "wpiris1",
        "dic",
        "uhrf",
        "tdf",
        "mappit",
        "ttiris1i",
        "ttiris1s",
        "unswir1",
        "ttf",
        "spiral",
        "3d",
        "tsp",
        "wfi",
        "iris2i",
        "iris2s",
        "cps",
        "omegaifu",
        "cycles",
        "visitor",
        "Cyclops 2 + UCLES",
        "cycles2"
    ],
    topends: [
        "PF",
        "F/8",
        "F/15",
        "F/36",
        "CS36",
        "F/1",
        "2DF",
    ],
    obs_type:[
        {value:"Object",name:"RUN"}, //#Object
        {value:"BIAS",name:"BIAS"},
        {value:"DARK",name:"DARK"},
        {value:"FLAT",name:"FLAT"},
        {value:"FFLAT",name:"FFLAT"},
        {value:"ARC",name:"ARC"},
        {value:"ANY",name:"ANY"} // add to allow user filer data
    ],
    obs_type_df:[
        {value:"MFOBJECT",name:"OBJECT"}, //#Object
        {value:"DARK",name:"Dark"},
        {value:"BIAS",name:"Bias"},
        {value:"MFSKY",name:"Offset Sky"},
        {value:"SFLAT",name:"Offset Flat"},
        {value:"DFLAT",name:"Detector Flat"},
        {value:"MFFFF",name:"Fiber Flat"},
        {value:"MFARC",name:"Arc"},
        {value:"MFFLX",name:"Flux Cal"}
    ],
    //
    obs_type_herme:[
        {value:"DARK",name:"Dark"},
        {value:"BIAS",name:"Bias"},
        {value:"LFLAT",name:"LFLAT"},
        {value:"MFARC",name:"Arc"},
        {value:"MFFFF",name:"Fiber Flat"},
        {value:"MFFLX",name:"Flux Cal"},
        {value:"MFOBJECT",name:"OBJECT"}, //#Object
        {value:"MFSKY",name:"Offset Sky"},
        {value:"SFLAT",name:"Offset Flat"},
        {value:"DFLAT",name:"Detector Flat"},
        {value:"ANY",name:"ANY"} // add to allow user filer data
    ]
}

module.exports = config;

