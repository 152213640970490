import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from "@mui/material/Button";
export default function Home() {
    return (
        <React.Fragment>
            <div className="hero is-fullheight" style={{
                background: '#000 url("/aat.jpg") no-repeat left center fixed',
                backgroundSize: 'cover'
            }}>
                <div className="hero-body">
                    <div className="container is-widescreen ">
                        <div className="columns has-text-left">
                            <div className="column is-6" >
                                <h1 className="title is-1 has-text-light">AAT Archive</h1>
                                <h4 className="subtitle is-4 has-text-light">
                                    The AAT Archive is an ongoing service to provide online access to the data observed
                                    to-date on the Anglo-Australian Telescope (archives for the TAIPAN instrument on
                                    UK Schmidt telescope and the Huntsman telescope are in-progress).
                                </h4>
                                <Button variant="contained" color="primary" className="m-r-sm" size="medium"
                                        component={RouterLink} to="/query">Query</Button>
                                <Button variant="outlined" color="secondary" size="medium" component={RouterLink}
                                        to="/logbook">Logbook</Button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}