import React from 'react';
import Loginc from './Loginc';

export default function PrimaryNav({user, setUserName}) {
  return (
    <React.Fragment>
    
        <nav className="navbar is-white p-none navstyle" role="navigation" aria-label="main navigation">
            <div className="container is-widescreen">
                <div className="navbar-brand navbar-style" >
                    <a className="navbar-item" href="https://datacentral.org.au" title="Back to Data Central">
                      <img src={process.env.REACT_APP_STATIC_FILE+"/img/logo/Data Central_AlternativeLogo_FullColour.png"} style={{minHeight:35}} alt="Data Central Logo" />
                    </a>

                     <a role="button" href="#/" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navBarPrimary">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navBarPrimary" className="navbar-menu">
        <div className="navbar-end">
          <a className="navbar-item" href="https://datacentral.org.au">
            Data
          </a>

          <a className="navbar-item" href="https://docs.datacentral.org.au">
            Docs
          </a>

          <a className="navbar-item" href="https://datacentral.org.au/api/">
            API
          </a>
          <div className="navbar-item has-dropdown is-hoverable">

            <a className="navbar-link is-arrowless" href="#/">
              About
              <i className="m-l-xs m-t-xxs fas fa-caret-down has-text-grey"></i>
            </a>
            <div className="navbar-dropdown">
              <a className="navbar-item" href="https://datacentral.org.au/about/">Team</a>
              <a className="navbar-item" href="https://docs.datacentral.org.au/timeline/">Timeline</a>
              <a className="navbar-item" href="https://docs.datacentral.org.au/blog/">Blog</a>
              <a className="navbar-item" href="https://docs.datacentral.org.au/reference/acknowledgements/">Acknowledging Data Central</a>
              <hr className="navbar-divider" />
              <a className="navbar-item" href="https://docs.datacentral.org.au/help-center/workshops/">Workshops</a>
              <hr className="navbar-divider" />
              <a className="navbar-item" href="https://docs.datacentral.org.au/outreach/">Educational Videos</a>
            </div>
          </div>


          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link is-arrowless">
              Support <i className="m-l-xs m-t-xxs fas fa-caret-down has-text-grey"></i>
            </div>
            <div className="navbar-dropdown">
              <a className="navbar-item" href="https://jira.aao.org.au/servicedesk/customer/portal/3">Contact</a>
              <a className="navbar-item" href="https://jira.aao.org.au/servicedesk/customer/portal/3/create/29">Bug Report</a>
              <a className="navbar-item" href="https://jira.aao.org.au/servicedesk/customer/portal/3/create/30">Feature Request</a>
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <div className="navbar-link is-arrowless">
              My Tools <i className="m-l-xs m-t-xxs fas fa-caret-down has-text-grey"></i>
            </div>
            <div className="navbar-dropdown">
              <a className="navbar-item" href="https://docs.datacentral.org.au">Document Central</a>
              <a className="navbar-item" href="https://datacentral.org.au/wiki">
                Team Wiki
              </a>
              <a className="navbar-item has-text-grey-light" href="#/">
                In Prep
              </a>
              <a className="navbar-item" href="https://cloud.datacentral.org.au">
                Cloud
              </a>
              <a className="navbar-item" href="https://teams.datacentral.org.au">
                Team Management <span className="tag is-success m-l-xs">New</span>
              </a>
            </div>
          </div>

          <Loginc username={user} setUserName={setUserName}/>
          
        </div>
      </div>
    </div>
        </nav>
      </React.Fragment>
        
  );
}
