import React from 'react';
import {withRouter} from 'react-router-dom';
import config from "../config";
import { AppContext } from '../reducers/store';
import Button from "@mui/material/Button";
import {submitQuery,sendrequest} from '../services/requestsetting'
import QueryTable from './QueryTable';
import Nav from './Nav'

const validateFunction = (values) => {
  /**
   * Form-scale validation to ensure at least some inputs are entered
   */
  const errors = {};
  if (values.start_date && values.end_date) {
    const start_date = new Date(values.start_date)
    const end_date = new Date(values.end_date)
    if (start_date.getTime() > end_date.getTime()) {
      errors.start_date = "Start date must be before end date"
    }
  }
  if (values.start_date && !values.end_date) {
    errors.end_date = "You must enter BOTH start date and end date"
  }
  if (values.end_date && !values.start_date) {
    errors.start_date = "You must enter BOTH start date and end date"
  }
  if (!values.proID && !(values.ra && values.dec) && !(values.start_date && values.end_date)) {
    errors.formLevel = "You must provide at least one of the following; program ID, ra and dec, start and end date."
  }

  return errors;
}

class QueryPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showResetQuestion: false,
      query_id: this.props.match.params.query_id,
      obs_type: config.obs_type,
      is_disabled:this.props.match.params.query_id!==undefined?true:false //this.state.query_id!==undefined
    }
    this.handleToggleResetQuestion = this.handleToggleResetQuestion.bind(this);
    this.functionerror= this.functionerror.bind(this)
    this.functiongetquerydata=this.functiongetquerydata.bind(this)
    this.querydata=this.querydata.bind(this)
    this.handleChange=this.handleChange.bind(this)
    this.handleResetQueryID=this.handleResetQueryID.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if(this.state.query_id!==undefined)
      this.functiongetquerydata();
    else{
      if(this.context.state.query.instrument==='2dF+AAOMEGA') this.setState({obs_type:config.obs_type_df})
      else if(this.context.state.query.instrument==='2dF+HERMES') this.setState({obs_type:config.obs_type_herme})
    }
    
    this.context.dispatch({
      type: 'RESULTS',
      payload: []
    });
    console.log('project start')
    console.log(this.state.query)
    
  }

  handleToggleResetQuestion(value) {
    this.setState(state => ({
      showResetQuestion: value
    }));
  }

  handleResetQueryID(){
    this.setState(state => ({
      query_id: undefined,
      is_disabled:false
    }))
  }

  functionerror (error) {
      if(error==='Error: 400') alert('Please set valid Filter, Data less than 18 months old can only be distributed to a member of the observing team')
      if(error==='Error: 401') alert('Access Denied with Program ID. If you think this is in error, please contact lens@datacentral.org.au')
      console.log(error)
    }

  functiongetquerydata() {
    if(this.state.query_id!==undefined){
      sendrequest({uuid:this.state.query_id,request:true}, this.querydata,this.functionerror,null)
    }
  }

  handleChange(event,obs_types){
    if(this.state.obs_type[0]!==obs_types[0]) this.setState({obs_type:obs_types})
    else console.log('not change')
    const query= {...this.context.state.query,instrument:event.target.value}
    this.context.dispatch({
      type: 'QUERY',
      payload:query
    });
    console.log(this.context.state.query)
  }

  querydata(data){
    const dataquery=data[0].query
    if(dataquery.obs_date!==undefined){
      dataquery['start_date'] = dataquery.obs_date
      dataquery['end_date'] = dataquery.obs_date
    }
    const query= {...this.context.state.query,...dataquery}
    if(dataquery.instrument==='2dF+AAOMEGA') this.setState({obs_type:config.obs_type_df})
    else if(dataquery.instrument==='2dF+HERMES') this.setState({obs_type:config.obs_type_herme})
    this.context.dispatch({
      type: 'QUERY',
      payload:query
    });
  }

  handleSubmit(values,setSubmitting) {
    console.log("query submitted.");
    console.log(values)
    const submitvalue={...values,instrument:this.context.state.query.instrument}
    this.context.dispatch({
      type: 'QUERY',
      payload: submitvalue
    });
    
    if(this.state.query_id=== undefined)
      submitQuery(submitvalue, this.props.history, this.context.dispatch, this.props.user, this.functionerror,setSubmitting);
    else
      submitQuery({'uuid':this.state.query_id,'data':true}, this.props.history, this.context.dispatch, this.props.user, this.functionerror,setSubmitting);
  }


  render(){
    const isnotvalid=Object.keys(validateFunction(this.context.state.query)).length !== 0 &&this.state.query_id!==undefined?true:false
    
    return ( 
    <div>
      <div className="container is-widescreen">
      <Nav />
        <h4 className="title is-4 ">Query the AAT Archive</h4>
        
        <h4 className="subtitle is-6 has-text-grey">Search for and download data from the AAT Archive</h4>
        <hr style={{borderTop: '2px solid #f5f5f5'}}/>
        
        <div className="columns m-t-md">
          <div className="column is-7">
            {isnotvalid?
            <div>
            The query information is not avaliable now, make sure your ID is correct and refresh the page Or <br/>
              <Button type="reset" variant="outlined" onClick={this.handleResetQueryID} >
                  Start New Query
              </Button></div>:
             
            
              <QueryTable
              is_query={true}
              initialValues={this.context.state.query}
              validateFunction={validateFunction}
              handleSubmit={this.handleSubmit}
              showResetQuestion={this.state.showResetQuestion}
              handleToggleResetQuestion={this.handleToggleResetQuestion}
              handleInstrumnetChange={this.handleChange}
              obs_type={this.state.obs_type}
              />
            }
          </div>
          <div className="column is-5 has-text-justified">
            <div className="content box">
              <h5 className="title is-5">Help and tips</h5>
              <p>
                The AAT Archive is an ongoing service to provide online access to the data observed to date
                on the Anglo-Australian Telescope
                (archives for the TAIPAN instrument on UK Schmidt telescope and the Huntsman telescope are
                in-progress). View the <a className=" font-l"
                                          href="https://docs.datacentral.org.au/archive/aat-archive/">documentation</a> for
                information on querying, retrieving and reducing AAT observations.
              </p>
              <div className="message is-info" data-bulma-attached="attached">
                <div className="message-body">
                  <strong>An 18 month proprietary period is in force.</strong>&nbsp;
                  Data less than 18 months old can only be distributed to a member of the observing team.&nbsp;
                  <a href="https://jira.aao.org.au/servicedesk/customer/portal/3">Contact us</a> if you are a member of
                  the observing team and would like to retrieve proprietary data.
                </div>
              </div>
              <h5 className="title is-5">Speeding up your search</h5>
              <p>
                Filtering by a specific
                instrument can significantly improve the query time. Adding a date range filter can also
                improve the query time. Note that currently a maximum of 10000 rows are returned.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>)
  }

}

QueryPage.contextType = AppContext;
export default withRouter(QueryPage)
