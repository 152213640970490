import React, {useContext} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import ResultsTable from './ResultsTable';
import myData from '../data/testResultFilename.json';
import {AppContext} from "../reducers/store";
import {useParams} from 'react-router-dom';
import Nav from './Nav'
const QueryResults = (props) => {

    let data = [];
    let badQuery = false;

    const {state} = useContext(AppContext);
    const {query_id} = useParams();

    // if local development, load in the test data json rather than hit the DB
    const use_db = true;
    let dummy_data = false;
    if (process.env.NODE_ENV === 'development' && !use_db) {
        data = myData
        dummy_data = true
    } else {
        if (typeof state.results === "undefined") {
            badQuery = true
        } else {
            data = state.results
        }
    }

    return <div>
        {dummy_data && <div className="notification is-warning">
                <div className="container"><strong>WARNING</strong> You are running the archive
                    in <code>development</code> mode. Results are not from the database.
                    To query the DB, use a different docker-compose file (e.g., staging), or switch the
                    <code>use_db</code> variable in src/components/QueryResults.js to true.
                </div>
        </div>}
        {badQuery &&
        <div className="container p-t-md">
            <div className="message">
                <div className="message-body">
                    No query data was submitted. Please go to the
                    <NavLink to='/query' className="has-text-weight-bold"> Query page</NavLink> and try again.
                </div>
            </div>
        </div> }
        {props.user===false&&
        <div className="container p-t-md">
        <div className="message">
            <div className="message-body">
            The query result is not avaliable now. Please make sure your ID is correct and refresh the page or go to the
                <NavLink to='/query' className="has-text-weight-bold"> Query page</NavLink> and try again.
            </div>
        </div>
        </div>}
        {props.user!==false&&!badQuery &&
        <div className="container is-widescreen">
            <Nav />
            <h4 className="title is-4 ">Query Results</h4>
            <h4 className="subtitle is-6 has-text-grey">View the top 10,000 results and download associated
                    files.</h4>
            <hr style={{borderTop: '2px solid #f5f5f5'}}/>
            <ResultsTable data={data} user={props.user} query_id={query_id} />
            <hr/>
        </div>}
    </div>
}

export default withRouter(QueryResults);
