import React, {createContext, useReducer} from 'react';

const initialState = {
    query: {
        proID: "",
        ra: "",
        dec: "",
        radius: 60,
        instrument: "",
        start_date: "",
        end_date: "",
        obstype: "",
    },
    logbook: {
        obsDate: ""
    },
    results: [],
};

const AppContext = createContext(initialState);

const { Provider } = AppContext;

const StateProvider = ({children}) => { 
    const [state, dispatch] = useReducer((state, action) => {   

        switch (action.type) {
            case 'QUERY':
                console.log('QUERY reducer PAYLOAD', action.payload);
                return { ...state,
                    query: action.payload
                }
            case 'LOGBOOK':
                console.log('LOGBOOK reducer PAYLOAD', action.payload);
                return { ...state,
                    logbook: action.payload
                }
            case 'RESULTS' :
                console.log('RESULTS reducer PAYLOAD', action.payload)
                return {
                    ...state,
                    results: action.payload
                }
            default:
                return state;
        }

    }, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export { AppContext, StateProvider };
