import React from 'react';
import PropTypes from 'prop-types';


export default class CellModalContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            title: props.title,
        }
    }

    render() {
        const {value, title} = this.state;
        if (value.constructor === Object) {
            return <table className="table">
                <thead>
                <tr>
                    <th>Keyword</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {
                    Object.keys(value).map(function (key) {
                        return <tr key={key}>
                            <td className="has-text-bold">{key}</td>
                            <td>{typeof value[key] !== 'object' ? value[key] : JSON.stringify(value[key])}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        } else {
            return <table className="table">
                <thead>
                {<tr>
                    {Object.keys(value[0]).map(function (key) {
                        return <th key={key}>{key}</th>
                    })}
                </tr>}
                </thead>
                <tbody>
                {
                    value.map(function (row, i) {
                        return <tr key={title + i}>
                            {Object.keys(row).map(function (key) {
                                return <td key={title + i + row[key].toString()}>{row[key]}</td>
                            })}
                        </tr>
                    })
                }
                </tbody>
            </table>
        }

    }
}

CellModalContent.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]).isRequired,
    title: PropTypes.string.isRequired
};