import React from 'react';
import Link from '@mui/material/Link';
// import {
//     useLocation
// } from "react-router-dom";

function Copyright() {

    return (
        <div align="center" className="has-text-light">
            {'Copyright © '}
            <Link color="inherit" href="https://datacentral.org.au/">
                Australian Astronomical Optics | Data Central
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </div>
    );
}

const footers = [
    {
        title: 'About',
        description: [
            ['Team', 'https://datacentral.org.au/about/', ''],
            ['Documentation', 'https://docs.datacentral.org.au/archive/new-aat-archive/', ''],
            ['Acknowledgements', 'https://docs.datacentral.org.au/reference/acknowledgements/', '']
        ],
    },
    {
        title: 'Features',
        description: [['API access', '#', 'Coming soon'], ['Team access', '#', 'Coming soon']],
    },
    {
        title: 'Legal',
        description: [['Privacy policy', '#', 'Coming soon'], ['Terms of use', '#', 'Coming soon']],
    },
];


export default function Footer() {
    // let location = useLocation();
    // console.log(location)

    return (
        <React.Fragment>
            <footer className="footer has-background-dark has-text-light">
                <div className="">
                    <div className="container is-widescreen">
                        <div className="columns m-b-lg">
                            <div className="column is-4">
                                <div className="level">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <a className="image is-64x64" href="https://datacentral.org.au">
                                                <img
                                                    src = {process.env.REACT_APP_STATIC_FILE+"/img/logo/Data Central_IconOnly_1Col-Grey.png"}
                                                    alt="Data Central Logo"/>
                                            </a>

                                        </div>
                                        <div className="level-item">
                                            <div className="p-l-sm has-text-justified">
                                                <h3 className="has-text-white title is-3 m-b-xs m-t-none"><span
                                                    className="has-text-weight-light">data</span>&nbsp;central
                                                </h3>
                                                <div className="level is-mobile has-text-grey-light">
                                                    <div className="level-item"><strong className="has-text-grey-light">Follow
                                                        us </strong>
                                                    </div>
                                                    <div className="level-item">
                                                        <a href="https://twitter.com/DataCentralOrg" target="_blank"
                                                           rel="noopener noreferrer"
                                                           className="m-r-xs has-text-grey-light">
                                                            <i className="fab fa-twitter"
                                                               aria-label="Data Central Twitter"/>
                                                        </a>
                                                    </div>
                                                    <div className="level-item">
                                                        <a href="https://www.facebook.com/Data-Central-642496366138483/"
                                                           target="_blank" className="m-r-xs has-text-grey-light"
                                                           rel="noopener noreferrer">
                                                            <i className="fab fa-facebook"
                                                               aria-label="Data Central Facebook"/>
                                                        </a>
                                                    </div>
                                                    <div className="level-item">
                                                        <a href="https://www.youtube.com/playlist?list=PLlyINwcIEhd8JtSTKpSQ6X-1Hntoq-SbA"
                                                           className="has-text-grey-light" target="_blank"
                                                           rel="noopener noreferrer">
                                                            <i className="fab fa-youtube"
                                                               aria-label="Data Central YouTube"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {footers.map((footer) => (
                                <div className="column" key={footer.title}>
                                    <h6 className="is-subtitle ">{footer.title}</h6>
                                    <ul>
                                        {footer.description.map((item) => (
                                            <li key={item[0]} className="m-b-xs">
                                                <Link href={item[1]} className="has-text-grey">
                                                    {item[0]}
                                                    {item[2] ? <span
                                                        className="tag m-l-sm is-pulled-right is-right">{item[2]}</span> : ''}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <Copyright/>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
